import React from "react";
import type {
  ActionFunction,
  CookieSerializeOptions,
  LoaderFunction,
} from "@remix-run/node";
import { redirect } from "@remix-run/node";
import { createHeaders, sessionStorage } from "~/services/session.server";
import SignInForm from "~/UI/3-organisms/SingInForm";
import { Link, useActionData, useTransition } from "@remix-run/react";
import type { Cookie } from "set-cookie-parser";
import setCookie from "set-cookie-parser";
import { useTranslation } from "react-i18next";
import { ApolloQueryResult } from "@apollo/client";
import client from "~/lib/graphql-client";
import gql from "graphql-tag";
import { gql as rawGql, rawRequest } from "graphql-request";
import { LoggedInUserDataQuery } from "~/generated/graphql";
import { serverErrors } from "./dashboard";

const GetFormData = gql`
  query LoggedInUserData {
    me {
      therapist {
        id
        status
      }
      errors {
        __typename
        ... on BaseError {
          message
        }
      }
    }
  }
`;

export const loader: LoaderFunction = async ({ request }) => {
  const res: ApolloQueryResult<LoggedInUserDataQuery> = await client.query({
    query: GetFormData,
    fetchPolicy: "network-only",
    context: { headers: await createHeaders(request) },
  });
  const { me } = res.data;
  if (
    me.therapist ||
    !me.errors?.find((error) => serverErrors.includes(error.__typename))
  ) {
    throw redirect("/dashboard/general");
  }

  return null;
};

const SignIn = rawGql`
  mutation SignIn($email: String!, $password: String!) {
    authenticateUserWithPassword(email: $email, password: $password) {
      token
      item {
        email
        id
        firstName
        lastName
      }
    }
  }
`;

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData();
  const email = formData.get("email");
  const password = formData.get("password");
  try {
    const response = await rawRequest(
      `${process.env.API_ENDPOINT}/graphql/`,
      SignIn,
      { email, password }
    );

    const setCookieHeader = await response.headers.get("set-cookie");

    if (!setCookieHeader) {
      return { error: "Something went wrong!" };
    }

    const parsedResponseCookies = setCookie.parse(
      setCookie.splitCookiesString(setCookieHeader as string)
    );
    const sessionIdCookie = parsedResponseCookies.find(
      (cookie) => cookie.name === "sessionid"
    );

    const headers = new Headers();

    const { name, value, ...sessionIdCookieSerializeOptions } =
      sessionIdCookie as Cookie;
    const sessionIdSession = await sessionStorage.getSession(
      request.headers.get("Cookie")
    );

    sessionIdSession.set(name, value);
    headers.append(
      "Set-Cookie",
      await sessionStorage.commitSession(
        sessionIdSession,
        // Use the response's `sessionid` cookie serialization options.
        sessionIdCookieSerializeOptions as CookieSerializeOptions
      )
    );
    return redirect("/dashboard/general", { headers });
  } catch (e) {
    const { response } = e as { response: { errors: { message: string }[] } };

    return { error: response.errors[0]?.message };
  }
};

function SignInPage() {
  let { t } = useTranslation();
  const actionData = useActionData() || { error: null };
  const transition = useTransition();
  return (
    <main
      className={
        "mx-auto mx-auto mb-21 mt-2 flex w-full w-full flex-col items-center tablet:my-18"
      }
    >
      <h1
        data-testid={"sign-in-title"}
        className="typography-3xl mb-6 text-center text-black100"
      >
        {t("login:title")}
      </h1>
      <SignInForm
        loading={transition.state === "loading"}
        error={actionData.error}
        className={
          "m-auto mb-5 w-full rounded-xl py-4 md:border md:border-gray200 tablet:w-83 tablet:p-5"
        }
      />
      <div className="text-center">
        <span className="typography-sm-semibold mr-1 text-black050">
          {t("login:no-account")}
        </span>
        <Link
          to={`/claim-your-profile`}
          data-testid={"sign-up-link"}
          className="typography-sm-bold text-green200"
        >
          {t("login:signup")}
        </Link>
      </div>
    </main>
  );
}

export default SignInPage;
