import classnames from "classnames";
import React from "react";
import SubmitButton from "../../2-molecules/SubmitButton";
import { Form, Link } from "@remix-run/react";
import TextInput from "~/UI/2-molecules/Inputs/TextInput";
import PasswordInput from "~/UI/2-molecules/Inputs/PasswordInput";
import { useTranslation } from "react-i18next";

export interface SignInFormProps {
  error?: string;
  loading?: boolean;
}
export interface LoginFormInput {
  email: string;
  password: string;
}

function SignInForm({
  error,
  loading,
  ...props
}: SignInFormProps & React.HTMLProps<HTMLDivElement>) {
  const { t } = useTranslation();

  return (
    <div {...props}>
      <Form method="post">
        <TextInput
          data-testid={"email"}
          name={"email"}
          label={t("general:labels.email")}
          className="mb-5"
        />
        <PasswordInput
          data-testid={"password"}
          name={"password"}
          label={t("general:labels.password")}
          className="mb-6 tablet:mb-5"
        />
        <SubmitButton
          loaderLabel={t("general:prompts.submitting")}
          loading={Boolean(loading)}
          data-testid={"submit-button"}
          type="submit"
          className="mb-6 w-full tablet:mb-4"
        >
          <span className="typography-1-bold tablet:typography-base-bold m-auto text-whitefff">
            {t("general:buttons.signin")}
          </span>
        </SubmitButton>
      </Form>

      {error && (
        <div
          data-testid={"login-error"}
          aria-live="polite"
          role="status"
          className={classnames(
            "typography-base-bold my-6 text-left text-red100 tablet:my-4"
          )}
        >
          {error}
        </div>
      )}

      <Link
        to={"/forgot-password"}
        data-testid={"forgot-password-link"}
        className="typography-sm-bold border-b border-black050 pb-1 pt-1 text-black050 tablet:pt-0"
      >
        {t("login:forgot-password")}
      </Link>
    </div>
  );
}

export default SignInForm;
